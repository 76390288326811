import useJwt from '@/auth/jwt/useJwt'

export default class MonthlyGoalsService {
  static byMonth(filters) {
    const { year, month } = filters

    return useJwt.get(`/monthly-goals/${year}/${month}`)
  }

  static createOrEdit(data) {
    return useJwt.put('/monthly-goals', {}, data)
  }
}
