<template>
  <section>
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="3">
              <b-form-group
                label="Mês"
                label-for="filter_month"
              >
                <b-form-select
                  id="filter_month"
                  v-model="filters.month"
                  :options="months"
                  :disabled="isLoading"
                  @input="getMonthlyGoals"
                />
              </b-form-group>
            </b-col>

            <b-col cols="3">
              <b-form-group
                label="Ano"
                label-for="filter_year"
              >
                <b-form-select
                  id="filter_year"
                  v-model="filters.year"
                  :options="goalManagementYearsFilter"
                  :disabled="isLoading"
                  @input="getMonthlyGoals"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="3"
              class="form-buttons"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-limpar"
                  variant="outline-secondary"
                  :disabled="isLoading"
                  @click="reset()"
                >
                  Mês atual
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card
          v-if="isLoading"
          class="text-center"
        >
          <b-spinner />
        </b-card>
        <b-card
          v-else
        >
          <b-row
            class="header-card"
            align-v="center"
          >
            <b-col>
              <h4>
                Mês {{ (editingMonthlyGoals.month + '').padStart(2, '0') }}/{{ editingMonthlyGoals.year }}
              </h4>
            </b-col>

            <b-col
              v-if="!isLoading"
              class="text-right"
            >
              <b-button
                v-if="wasEdited"
                variant="primary"
                class="ml-2"
                @click="saveMonth"
              >
                <span class="text-nowrap">Salvar alterações</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <small>
                Dias de semana no mês (incluindo feriados): {{ estimatedBusinessDaysInListedMonth }}
              </small>
              <hr>
              <b-form-group
                :label="`Dias úteis no mês`"
                label-for="business-days"
              >
                <b-form-spinbutton
                  id="business-days"
                  v-model="editingMonthlyGoals.business_days"
                  min="1"
                  wrap
                  :max="maxBusinessDaysInListedMonth"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-row
                class="header-card"
                align-v="center"
              >
                <b-col>
                  <h4>
                    Metas
                  </h4>
                </b-col>

                <b-col
                  v-if="!isLoading"
                  class="text-right"
                >
                  <b-button
                    variant="secondary"
                    class="ml-2"
                    @click="copyFromPriorMonth"
                  >
                    <small>
                      Copiar metas<br>
                      do mês anterior
                    </small>
                  </b-button>

                  <b-button
                    variant="secondary"
                    class="ml-2"
                    @click="openCreateGoalModal()"
                  >
                    <span class="text-nowrap">Criar meta</span>
                  </b-button>
                </b-col>
              </b-row>
              <b-table
                ref="table"
                responsive
                :items="editingMonthlyGoals.goals"
                :fields="[
                  {
                    key: 'role',
                    isRowHeader: true,
                  },
                  {
                    key: 'slug',
                  },
                  {
                    key: 'value',
                  },
                  {
                    key: 'interval',
                  },
                  {
                    key: 'actions',
                  },
                ]"
                no-local-sorting
              >
                <template #head(role)>
                  <div class="text-nowrap">
                    Setor
                  </div>
                </template>

                <template #head(slug)>
                  <div class="text-nowrap">
                    Chave
                  </div>
                </template>

                <template #head(value)>
                  <div class="text-nowrap">
                    Valor
                  </div>
                </template>

                <template #head(interval)>
                  <div class="text-nowrap">
                    Periodicidade
                  </div>
                </template>

                <template #head(actions)>
                  <div class="text-nowrap text-center">
                    Ações
                  </div>
                </template>

                <template #cell(role)="row">
                  {{ t(`roles.${row.item.role}`) }}
                </template>

                <template #cell(name)="row">
                  {{ row.item.slug }}
                </template>

                <template #cell(value)="row">
                  <div v-if="row.item.interval && row.item.interval === 'daily'">
                    {{ formatLocalizeNumber(row.item.value) }}
                    <span
                      :class="{
                        'text-danger': isMonthlyValueFromDailyGoalChanged(row.item)
                      }"
                    >
                      ({{ formatLocalizeNumber(row.item.value * Math.max(1, editingMonthlyGoals.business_days)) }}/mês)
                    </span>
                  </div>
                  <div v-else>
                    {{ row.item.value }}
                  </div>
                </template>

                <template #cell(interval)="row">
                  {{ row.item.interval ? t(`goals.intervals.${row.item.interval}`) : t('goals.intervals.monthly') }}
                </template>

                <template #cell(actions)="row">
                  <b-button
                    v-if="$can('delete', 'Tools')"
                    variant="flat-primary"
                    title="Deletar"
                    @click="deleteGoal(row.item)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </b-button>
                  <b-button
                    v-if="$can('delete', 'Tools')"
                    variant="flat-primary"
                    title="Deletar"
                    @click="editGoal(row.item)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      ref="modal-create-goal"
      title="Definir meta"
      cancel-title="Cancelar"
      ok-title="Definir meta"
      @ok="handleCreateGoal"
    >
      <validation-observer ref="create-goal-observer">
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-form-group
                label="Setor"
                label-for="create-goal-role"
              >
                <b-form-select
                  id="create-goal-role"
                  v-model="createGoal.role"
                  :options="goalRoles"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-form-group
                label="Chave"
                label-for="create-goal-slug"
              >
                <b-form-input
                  id="create-goal-slug"
                  v-model="createGoal.slug"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :rules="{
                required: true,
                regex: /^\d+(?:\,\d+)?$/
              }"
            >
              <b-form-group
                label="Valor"
                label-for="create-goal-value"
              >
                <b-form-input
                  id="create-goal-value"
                  v-model="createGoal.value"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              rules="required"
            >
              <b-form-group
                label="Intervalo"
                label-for="create-goal-interval"
              >
                <b-form-select
                  id="create-goal-interval"
                  v-model="createGoal.interval"
                  :options="goalIntervals"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSpinbutton,
  BModal,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  goalManagementYearsFilter,
  months,
  goalRoles,
  goalIntervals,
} from '@/utils/options'
import MonthlyGoalsService from '@/services/monthlyGoalsService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { regex } from '@validations'
import { formatLocalizeNumber } from '@/utils/helpers'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BBreadcrumb,
    BButton,
    BCard,
    BCardActions,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSpinbutton,
    BModal,
    BRow,
    BSpinner,
    BTable,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    const today = new Date()
    const defaultFilters = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      business_days: null,
      goals: [],
    }
    const defaultCreateGoal = {
      role: '',
      slug: '',
      value: '',
      interval: 'monthly',
    }

    const { t } = useI18nUtils()

    return {
      regex,

      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'Gerenciamento de Metas',
          active: true,
        },
      ],
      goalManagementYearsFilter,
      months,
      isLoading: true,
      defaultFilters,
      filters: { ...defaultFilters },
      monthlyGoals: { ...defaultFilters },
      editingMonthlyGoals: { ...defaultFilters },
      goalToDeleteWhenEditing: null,
      defaultCreateGoal,
      createGoal: { ...defaultCreateGoal },
      goalRoles,
      goalIntervals,

      formatLocalizeNumber,
      t, // i18n
    }
  },
  computed: {
    wasEdited() {
      return JSON.stringify(this.editingMonthlyGoals) !== JSON.stringify(this.monthlyGoals)
    },
    maxBusinessDaysInListedMonth() {
      const { year, month } = this.editingMonthlyGoals
      const date = new Date(year, month, 1)
      date.setDate(0)

      const daysInMonth = date.getDate()

      return daysInMonth
    },
    estimatedBusinessDaysInListedMonth() {
      const { year, month } = this.editingMonthlyGoals
      return Array(this.maxBusinessDaysInListedMonth).fill(null).reduce((acc, _, day) => {
        const date = new Date(year, month - 1, day + 1)

        if (![0, 6].includes(date.getDay())) {
          return acc + 1
        }

        return acc
      }, 0)
    },
  },
  created() {
    this.getMonthlyGoals()
  },
  methods: {
    reset() {
      this.filters = { ...this.defaultFilters }
      this.getMonthlyGoals()
    },
    setMonthlyGoals(monthlyGoals) {
      this.editingMonthlyGoals = { ...JSON.parse(JSON.stringify(monthlyGoals)) }
      this.monthlyGoals = monthlyGoals
    },
    handleApiResponse(apiRequestPromise) {
      this.isLoading = true
      return apiRequestPromise.then(response => {
        if (response.data !== null) {
          const monthlyGoals = response.data.data.monthly_goals
          const yearMonth = monthlyGoals.date.split('-').map(n => parseInt(n, 10))

          this.setMonthlyGoals({
            ...this.defaultFilters,
            ...monthlyGoals,
            year: yearMonth[0],
            month: yearMonth[1],
          })
        }
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro!',
            text: error.message ? error.message : error.response.data.message,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    getMonthlyGoals() {
      this.handleApiResponse(MonthlyGoalsService.byMonth(this.filters))
    },
    deleteGoal(goalToRemove) {
      const { goals } = this.editingMonthlyGoals
      const indexToRemove = goals.findIndex(goal => goal.role === goalToRemove.role && goal.slug === goalToRemove.slug)

      goals.splice(indexToRemove, 1)

      this.editingMonthlyGoals = {
        ...this.editingMonthlyGoals,
        goals,
      }
    },
    openCreateGoalModal() {
      this.goalToDeleteWhenEditing = null
      this.createGoal = { ...this.defaultCreateGoal }
      this.$refs['modal-create-goal'].show()
    },
    editGoal(goal) {
      this.goalToDeleteWhenEditing = goal

      const value = formatLocalizeNumber(goal.value).replaceAll('.', '')

      this.createGoal = {
        ...this.defaultCreateGoal,
        ...goal,
        value,
      }

      this.$refs['modal-create-goal'].show()
    },
    saveMonth() {
      this.handleApiResponse(MonthlyGoalsService.createOrEdit(this.editingMonthlyGoals)).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Metas salvas com sucesso!',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    handleCreateGoal(event) {
      event.preventDefault()
      this.$refs['create-goal-observer'].validate().then(success => {
        if (!success) {
          return false
        }

        const {
          role,
          slug,
          value,
          interval,
        } = this.createGoal
        const { date, goals } = this.editingMonthlyGoals

        const newGoal = {
          date,
          role,
          slug,
          value: parseFloat(`${value}`
            .replaceAll('.', '')
            .replaceAll(',', '.')),
          interval,
        }

        if (this.goalToDeleteWhenEditing) {
          const indexToRemove = goals.findIndex(goal => goal.role === this.goalToDeleteWhenEditing.role && goal.slug === this.goalToDeleteWhenEditing.slug)
          goals.splice(indexToRemove, 1, newGoal)

          this.goalToDeleteWhenEditing = null
        } else {
          goals.splice(goals.length, 0, newGoal)
        }

        this.editingMonthlyGoals = {
          ...this.editingMonthlyGoals,
          goals: [...goals],
        }

        this.$refs['modal-create-goal'].hide()
        return true
      })
    },
    copyFromPriorMonth() {
      this.$swal({
        title: 'Atenção',
        text: 'Esta ação copiará apenas as metas do mês anterior que não existem no mês selecionado. Deseja continuar?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Continuar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary ml-1',
        },
      }).then(result => {
        if (!result.value) {
          return false
        }

        this.isLoading = true

        const { year, month } = this.editingMonthlyGoals
        const date = new Date(year, month - 2, 1)

        return MonthlyGoalsService.byMonth({
          year: date.getFullYear(),
          month: date.getMonth() + 1,
        }).then(response => {
          if (response.data !== null) {
            const previousMonthGoals = response.data.data.monthly_goals.goals || []
            const currentGoals = this.editingMonthlyGoals.goals || []

            const goals = [
              ...currentGoals,
              ...previousMonthGoals.filter(goal => (
                !currentGoals.find(f => (
                  (f.role === goal.role)
                  && (f.slug === goal.slug)
                ))
              )),
            ]

            this.editingMonthlyGoals = {
              ...this.editingMonthlyGoals,
              goals,
            }

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Metas copiadas com sucesso!',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          }
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro!',
            text: error.message ? error.message : error.response.data.message,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.isLoading = false
      })
    },
    isMonthlyValueFromDailyGoalChanged(goal) {
      const oldGoal = this.monthlyGoals.goals.find(f => (f.role === goal.role) && (f.slug === goal.slug))

      if (!oldGoal) {
        return false
      }

      const oldMonthlyValue = oldGoal.value
          * Math.max(1, this.monthlyGoals.business_days)

      const newMonthlyValue = this.editingMonthlyGoals.goals.find(f => (f.role === goal.role) && (f.slug === goal.slug)).value
          * Math.max(1, this.editingMonthlyGoals.business_days)

      return oldMonthlyValue !== newMonthlyValue
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
